<template>
    <div>
        <a-form :form="milesRemovalForm" @submit="handleSubmit">
            <div class="clearfix" />
            <a-row :gutter="20">
                <a-col :span="5">
                    <a-form-item class="travel-input-outer">
                        <label
                            :class="
                                milesRemovalForm.getFieldValue(`contract_id`)
                                    ? 'filled'
                                    : ''
                            "
                            >ID do contrato</label
                        >
                        <a-input
                            class="travel-input"
                            :placeholder="`ID do contrato`"
                            v-decorator="[
                                `contract_id`,
                                {
                                    initialValue: undefined,
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Obrigatório',
                                        },
                                    ],
                                },
                            ]"
                        >
                            <a-icon slot="prefix" type="field-svg" />
                        </a-input>
                    </a-form-item>
                </a-col>

                <a-col :span="8">
                    <a-form-item class="travel-input-outer">
                        <label
                            :class="
                                milesRemovalForm.getFieldValue(`miles_quantity`)
                                    ? 'filled'
                                    : ''
                            "
                            >Quantidade de milhas a retirar</label
                        >
                        <a-input
                            class="travel-input"
                            :placeholder="`Quantidade de milhas a retirar`"
                            v-currency="{
                                currency: {
                                    prefix: '',
                                    suffix: '',
                                },
                                distractionFree: false,
                                valueAsInteger: true,
                                precision: 0,
                                autoDecimalMode: false,
                                allowNegative: false,
                            }"
                            v-decorator="[
                                `miles_quantity`,
                                {
                                    initialValue: undefined,
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Obrigatório',
                                        },
                                    ],
                                },
                            ]"
                        >
                            <a-icon slot="prefix" type="field-svg" />
                        </a-input>
                    </a-form-item>
                </a-col>
                <a-col :span="8">
                    <a-button type="primary" html-type="submit">
                        RETIRAR MILHAS
                    </a-button>
                </a-col>
            </a-row>
            <div class="clearfix" />

            <a-modal
                title="Verificação de usuário"
                :visible="showPasswordModal"
                :footer="null"
                class="travel-modal"
                @cancel="showPasswordModal = false"
                width="400px"
            >
                <a-row :gutter="[20, 0]">
                    <a-col :span="16">
                        <input
                            name="email"
                            style="color: #FFF; opacity: 0; pointer-events: none; height: 0; padding: 0; border: 0;"
                            placeholder="Fake email fix"
                        />
                        <a-input
                            class="travel-input"
                            size="large"
                            v-model="userPassword"
                            type="password"
                            placeholder="Insira sua senha"
                        >
                            <a-icon
                                slot="prefix"
                                type="lock"
                                style="color: rgba(0, 0, 0, 0.25)"
                            />
                        </a-input>
                    </a-col>
                    <a-col class="a-right" :span="8">
                        <a-button
                            @click="userConfirmation"
                            class="mt-30"
                            type="primary"
                            :loading="loading"
                        >
                            Ver dados
                        </a-button>
                    </a-col>
                </a-row>
            </a-modal>
        </a-form>
    </div>
</template>

<script>
export default {
    props: {
        milesCard: Object,
    },
    data() {
        return {
            milesRemovalForm: this.$form.createForm(this),
            showPasswordModal: false,
            loading: false,
            userPassword: "",
        };
    },
    methods: {
        handleSubmit(e) {
            e.preventDefault();
            this.milesRemovalForm.validateFields((err, values) => {
                console.log(err, values);
                if (!err) {
                    this.showPasswordModal = true;
                }
            });
        },
        userConfirmation() {
            this.loading = true;
            this.$http
                .post("/token", {
                    email: this.$store.state.userData.email,
                    password: this.userPassword,
                })
                .then(({ data }) => {
                    data;
                    this.confirmMilesRemoval();
                })
                .catch(({ response }) => {
                    this.$message.error(response.data.message);
                    this.loading = false;
                });
        },
        confirmMilesRemoval() {
            this.$http
                .post("/miles-card/update-field", {
                    id: this.milesCard.id,
                    field: "status",
                    value: "available",
                    action: "subtract-milescard",
                    description: `<font class="user">${
                        this.$store.state.userData.first_name
                    } ${
                        this.$store.state.userData.last_name
                    }</font> RETIROU ${this.milesRemovalForm.getFieldValue(
                        "miles_quantity"
                    )} milhas do cartão <font class="id">ID ${
                        this.milesCard.id
                    } ${
                        this.milesCard.miles_program
                    }</font> para utilização no contrato <font class="id">ID ${this.milesRemovalForm.getFieldValue(
                        "contract_id"
                    )}</font>.`,
                    modified_by: {
                        name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
                        id: this.$store.state.userData.id,
                    },
                    read_card: true,
                    transaction: {
                        supplier_id: this.milesCard.supplier.id,
                        user_id: this.$store.state.userData.id,
                        total_miles: this.milesRemovalForm
                            .getFieldValue("miles_quantity")
                            .replace(".", "")
                            .replace(".", "")
                            .replace(".", "")
                            .replace(".", ""),
                        suggested_value: 0,
                        value: 0,
                        price_per_miles: this.milesCard.price_per_miles,
                        special_price: this.milesCard.special_price,
                        operation: "subtract",
                    },
                })
                .then(({ data }) => {
                    data;
                    this.showPasswordModal = false;
                    this.$message.success("Milhas Retiradas com sucesso!");
                    this.milesRemovalForm.setFieldsValue({
                        contract_id: null,
                        miles_quantity: null,
                    });
                    this.$emit("getMilesCard", true);
                    this.$emit("updateMilesCardsList", true);
                    this.loading = false;
                })
                .catch(({ response }) => {
                    this.$message.error(response.data.message);
                    this.loading = false;
                });
        },
    },
};
</script>
